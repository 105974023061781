* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

.main__page-title__description__content {
    font-size: 1.13333rem;
}

.main__page-title__description__content-I {
    font-size: 1.5rem;
    color: green;
}

.main {
    padding: 0 15px;
    /* background: #dbeee1; */
background: #e7e7e7;
    .main__content-wrapper {
        background-color: #fff;
        box-sizing: border-box;
        min-height: 451px;
    }
}

.section-wrapper{
    /* margin: 0 auto; */
}

.content-separator, .main-content {
    background-color: #fff;
    margin: 15px 0;
    padding: 0px;
    box-sizing: border-box;
}

.main__page-title{
    margin-bottom: 15px;
}

.main__page-title__name {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.main__page-title__description__content-I {
    margin-bottom: 0.6rem;
    font-weight: 600;
}

.main__page-title__description__content-II {
    font-weight: 400;
}

.main__page-title__description__required {
    margin: 10px 0 0 0;
    font-size: 1rem;
}

.line-seperator {
    margin: 1rem 0;
}

.availability {
    background-color: #E7E7E7;

    .availability__title {
        font-weight: 600;
        font-size: 1.3rem;
        margin-bottom: 0.7rem;
    }

    .form-check-input {
        height: 1rem;
        width: 1rem;
    }

    .availability__free-appointment__checkBox, .priority-appointment__checkBox {
        margin-right: 0.5rem;

    }

    .availability__free-appointment__label, .priority-appointment__label {
        font-size: 1rem;
    }
}

.selected-appointment-date {
    margin: 1.1rem 0;

    .selected-appointment-date__label {
        font-size: 1rem;
        font-weight: 600;
    }

    .selected-appointment-date__text {
        font-size: 0.9rem;
    }
}

.cell-phone-number {
    .cell-phone-number__label {
        font-size: 1rem;
        font-weight: 700;
    }


}

.cell-phone-number__hint {
    /* width:20rem; */
    font-size: 0.9rem;
    margin: 0 0 0.9rem 0;
}

.cellphone-number-info {
    background-color: #E8F0F7;
    border: 1px solid #b6d3ec;
    font-size: 1rem;
    margin-bottom: 0.9rem;

    .cellphone-number-info__img {
        width: 23px;
        height: 23px;
    }
}

.terms-and-condition {
    font-size: 1rem;
}

.technician-check-in {
    .technician-check-in__title, .technician-check-in__content {
        font-size: 1rem;
    }
}


.age-info {
    font-size: 1rem;
    line-height: 1.8rem;
}

#next_button {
    font-size: 1rem;
}

.gray-bg {
    background-color: #E7E7E7;
}

.terms-and-condition__label_I, .technician-check-in__title {

    font-weight: 700;
}
/* dnf means date bot found */
.dnf {
    display: flex;
    justify-content: center;
}
/*Mobile*/
@media (min-width: 320px) {

    /*75% => 12px*/
    html {
        font-size: 75%;
    }

    .main{
        background: #fff;
    }

}

/* Tablet and wider, i.e. the md breakpoint */
@media (min-width: 768px) {
    .main {
        /* background: #dbeee1; */
        background: #e7e7e7;
        /* padding: 20px; */
    }

    .section-wrapper{
        margin: 0;
    }

    .main__content-wrapper, .content-wrapper {
        width: 100%;
        padding: 30px;
    }

    .main__page-title{
        margin-bottom: 25px;
    }

    .main__page-title__name{
        margin-bottom: 25px;
    }

    .main__page-title__description__required{
        margin-top: 0;
        text-align: right
    }

    .line-seperator {
        margin: 1.65rem 0;
    }

    .content-separator {
        padding: 30px;
    }

    .country-name-text, .cell-phone-number__hint {
        width: 30rem;
    }

    footer {
        width: 100%;
    }
}

/* Labtop and wider, i.e. the md breakpoint */
@media (min-width: 1024px) {
    html {
        font-size: 81.25%;
    }

    .main {
        /* height: 69.22vh; Increase height for desktop screens */
    }

    .main__content-wrapper, .content-wrapper {
        /* width: 940px; */
        width: 100%;
    }

    .country-name-text, .cell-phone-number__hint {
        width: 30rem;
    }
}

@media (min-width: 1440px) {
    html {
        font-size: 87.5%;
    }

    .main {
        /* /   height: 69.22vh; Increase height for large desktop screens */
    }
    .section-wrapper{
        /* margin: 0 auto; */
    }
    .main__content-wrapper, .content-wrapper {
        /* width: 940px; */
        width: 100%;
    }

    .country-name-text, .cell-phone-number__hint {
        width: 35rem;
    }
}
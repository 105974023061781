
.simple-spinner {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .simple-spinner span {
    display: block;
    width: 40px;
    height: 40px;
    border: 4px solid transparent;
    border-radius: 50%;
    border-right-color: rgb(0 88 154);;
    /* border-right-color: rgba(255, 255, 255, 0.7); */
    animation: spinner-anim 0.8s linear infinite;
  }
  
  @keyframes spinner-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
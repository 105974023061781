.spokenOnly {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* scg-skin.css */
.af_document {
  position: static !important;
  background: #fff;
  /* commented out after introducing bootstrap: font-size: 62.5%;*/
  /* font-size 1em = 10px */
  line-height: 1.2;
  overflow: auto;
  overflow-x: hidden;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.fs-14 {
  font-size: 1.4em !important;
}

label {
  /* font-size: 1.5em; */
  /* font-weight: bold; */
}

.display-none {
  display: none !important;
}

/* scgres-theme.css */
.apt-calendar-widget .selected-apt-date a,
.apt-calendar-widget .selected-apt-date a:active {
  background: #266d2f !important;
  color: #fff !important;
}

@media only screen and (max-width: 599px) {
  .selAppt {
    display: block;
  }
}

.main{
    background: #fff;
}
.line-seperator{
    background: #212121;
    border: 0 none;
    display: block;
    height: 2px;
    width: auto;
}

.headerAndDesc, .panel-label-input {
    margin-bottom: 15px;
}

.panel-label-input label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.panel-label-input input[type="text"], .panel-label-input select {
    margin: 0;
    padding: 5px;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #CCC;
    font-size: 1rem;
}

.panel-label-input input[type="text"] {
    overflow-x: hidden;
    overflow-y: auto;
    color: #212121;
}


.form-input{
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; 
    width: 100%;
}
.form-input:focus {
    border: 1.5px solid black;
    outline: none !important;
    box-shadow: none;
}
.form-select{
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    /* width: 100%; */
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
    /* width: 220px !important; */
}

 .form-select:focus {
    border: 1.5px solid black;
    outline: none !important;
    box-shadow: none;
} 

.btn-orange {
	background-color: #d34600;
	background-image: none !important;
	box-shadow: inset 0px -3px #b03a00;
	-webkit-box-shadow: inset 0px -3px #b03a00;
	-moz-box-shadow: inset 0px -3px #b03a00;
  border: 0;
  cursor: pointer;
  padding: 10px 15px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.btn-orange:disabled {
    background-color: #d2d2d2;
    color: #9f9d9d;
    box-shadow: inset 0px -3px #9f9d9d;
    -webkit-box-shadow: inset 0px -3px #9f9d9d;
    -moz-box-shadow: inset 0px -3px #9f9d9d;
}

/* .form-input:focus{
    outline: none !important;
    box-shadow: none;
} */

.street-address-wrapper {
    position: relative;
}

.street-suggestion-wrapper{
    position: absolute;
    left: 0;
    top: 35px;
    z-index: 1;
    width: 25em;
    padding: 0 0.75em;
    
}

.street-suggestion-list-wrapper{
    width: 100%;
    background: white;
    padding: 0.5rem 0;
    border: 1px solid #CCC;
    max-height: 18em;
    overflow-y: auto;
}

.street-suggestion-list-wrapper div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0.8rem;
}

.street-suggestion-list-wrapper div:hover{
    background: #d2d2d2;
}

.total-address{
    width: max-content;
}

.message-container {
    background: #fff;
    font-size: 1em;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    padding: 15px 15px 15px 10px;
    display: flex;
    align-items: flex-start;
  }
  
  a {
    color: #039;
  }
  
  .message-container a {
    text-decoration: underline;
  }
  
  .message-icon {
      margin-top: 5px;
      margin-right: 10px;
      width: 23px;
  }
  .error-text-panel h1 {
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .error-text-panel ul {
    padding-left: 15px;
    font-size: 1em;
    margin-bottom: 0; /* we're inside a padded box; no need for for extra margin */
  }
  
  .success-text-panel h1 {
    font-size: 1.1em;
    font-weight: bold;
    color: #266d2f;
  }
  
  .success-text-panel p, .message-container p {
    font-size: 1em;
  }
  
  .message-container ul > li {
    padding-bottom: 10px;
  }
  
  .message-container ul > li:last-child {
    padding-bottom: 0px;
  }

@media (min-width: 320px){
    .street-suggestion-wrapper{
        width: 100%;
    } 
}

/* Tablet and wider, i.e. the md breakpoint */
@media (min-width: 768px){
    .panel-label-input {
        margin-bottom: 0;
    }
    .panel-label-input label{
        display: block;
        text-align: right;
        padding: 5px 0px 15px 0;
    }

    .panel-label-input input[type="text"], .panel-label-input select {
        min-width: 220px;
        width: 220px;
    }
    .main__content-wrapper{
        width: 100%;
    }
    .main{
        /* min-height: 514px; */
    }
    .street-suggestion-wrapper{
        width: 22em;
    }

    .message-container {
        border: none;
        padding: 0;
        margin-bottom: 0;
      }
    
      .message-icon {
        width: auto;
        margin-top: 0;
        margin-right: 20px;
      }
}

/* Labtop and wider, i.e. the md breakpoint */
@media (min-width: 1024px){
    .main__content-wrapper{
        width: 940px;
    }
    .main{
        /* min-height: 493px; */
    }
}

/* Labtop L and wider, i.e. the md breakpoint */
@media (min-width: 1440px){
    .main__content-wrapper{
        width: 940px;
    }
    .main{
        /* min-height: 600px; */
    }
}


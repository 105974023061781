/* scgres-theme.css */
.apt-calendar-widget .selected-apt-date a,
.apt-calendar-widget .selected-apt-date a:active {
  background: #266d2f !important;
  color: #fff !important;
}


.apt-calendar-widget *,
.apt-calendar-widget {
	padding: 0;
	margin: 0;
}

.apt-calendar-widget {
	max-width: 560px;
	border: 0;
	margin: 0 auto;
}

.apt-calendar-widget .apt-calendar-dates-table {
	border-collapse: collapse;
	/*font-weight: bold;*/
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

#apt-calendar .apt-calendar-left-arrow,
#apt-calendar .apt-calendar-right-arrow {
	background: black;
	color: white;
	min-width: 25px;
	text-align: center;
	height: 200px;
	display: block;
}
#priority-calendar .apt-calendar-left-arrow,
#priority-calendar .apt-calendar-right-arrow {
	background: black;
	color: white;
	min-width: 25px;
	text-align: center;
	height: 71px;
	display: block;
}

.apt-calendar-widget .arrow-left,
.apt-calendar-widget .arrow-right {
	width: 0; 
	height: 0; 
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
    position: relative;
    font-size: 0px;
}
.apt-calendar-widget .arrow-left {
	border-right: 18px solid #fff;
}
.apt-calendar-widget .arrow-right {
	border-left: 18px solid #fff;
}
#apt-calendar .arrow-left,
#apt-calendar .arrow-right {
    top: 102px;
}
#priority-calendar .arrow-left,
#priority-calendar .arrow-right {
    top: 40px;
}

.apt-calendar-widget .apt-calendar-dates-container {
	max-width:560px;
	overflow: hidden;
	position: relative;
}
#apt-calendar .apt-calendar-dates-container {
	height: 200px;
}
#priority-calendar .apt-calendar-dates-container {
	height: 71px;
}

.apt-calendar-widget .apt-calendar-dates-table {
	position: absolute !important;
	table-layout: fixed;
	width: 100%;
}

.apt-calendar-widget .apt-calendar-dates-table thead th {
	background: grey;
	color: #fff;
	height: 26px;
	border: 1px solid black;
	font-size: 14px;
}

.apt-calendar-widget .apt-calendar-dates-cell {
	width: 100%;
}

.apt-calendar-widget .apt-calendar-dates-table > tbody > tr > td {
	border: 1px solid black;
	text-align: center;
	height: 42px;
	vertical-align: top;
}

#apt-calendar .apt-calendar-dates-table > tbody > tr > td {
	/*font-size: 14px;*/
}
.apt-calendar-widget .apt-calendar-dates-table > tbody > tr > td {
	vertical-align: middle;
	font-weight: bold;
}

.apt-calendar-widget .apt-calendar-dates-table a,
.apt-calendar-widget .apt-calendar-dates-table a:active {
        font-size: 1em;
	height: 42px;
	color: #000;
	font-weight: bold;
	text-align: center;
	width: 102px;
	display: table-cell;
	vertical-align: middle;
	padding: 0 5px;
	cursor: inherit;
        text-decoration: none !important;
}

.apt-calendar-widget .AFContentCell {
	position: absolute;
	top: -99999px;
	left: -99999px;
}
.apt-calendar-widget td[role=gridcell]:hover a,
#priority-calendar td[role=gridcell]:hover {
    cursor: pointer;
    background: #E2E2E2;
}
.apt-calendar-widget .af_selectOneChoice {
    display: block;
    padding: 0 5px;
}
.apt-calendar-widget .af_selectOneChoice select {
	min-width: 0px !important;
        font-size: 12px;
}
.apt-calendar-widget .not-applicable-day {
    font-weight: bold;
    font-size: 14px;
}
#priority-calendar .selected-apt-date {
    background: #00478a;
}
.head-wrapper{
    border-top: 10px solid #005296;
}

.header {
    /* background: url('/public/bg_hdr_oma.png'); 
     background-position: 0 -10px;*/
     background: #ececec;
   
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    border-bottom: 4px solid #cccccc;
    box-sizing: border-box;
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding: 10px 15px 0 15px;
}

.header__logo-link{
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
}

.header__logo-link img{
    height: 40px;
}

@media (min-width: 768px) {
    .header {
        /* padding-top: 20px; */
        /* background-position: 0 0; */
        /* height: 100px; */
        border-top: 10px solid #005296;
        height: 99px;
    }
    .header__logo-link img{
        height: 40px;
    }
}


@media (min-width: 992px) {
    .header__logo-link img{
        height: 60px;
    }
}
/* Footer */
.footer-wrapper {
	border-top: 4px solid #cccccc;
    background: #fff;
    padding:25px 0;
}

.footer-copyright {
    text-align: center;
    color: #212121;
    font-size: 0.86rem;
  }

  .header__logo-link__logo{
    height: 50px;
}

  .item > a{
    line-height: 2rem;
    font-size: 1rem;
    font-weight: 800;
    color: #004B90;
  }

  @media (min-width: 768px) {
    .footer-copyright {
        text-align: left;
    }
}